import {Controller} from '@hotwired/stimulus';

function changeVisible(id) {
    let idControl = "#" + id + "-ts-control";
    let select = $(idControl).find("div.item").attr("data-value");
    let idElement = null;
    let fieldset = "div.form-fieldset.homePage"
    $(fieldset).hide();
    if (typeof select != "undefined") {
        let classArray = select.split("\\");
        let className = classArray[classArray.length - 1];
        $("div.form-fieldset.homePage."+className).show();
    }

}


export default class extends Controller {
    connect() {
        let id = this.element.id;
        let menuType = $("#" + id);
        $(document).ready(function () {

            menuType.off('change');
            menuType.on('change', function () {
                changeVisible(id);
            });
            changeVisible(id);
        });

    }
}

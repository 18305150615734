import { Controller } from '@hotwired/stimulus';

function changeVisible(id){
    let idControl = "#"+id+"-ts-control";
    let select = $(idControl).find("div.item").attr("data-value");
    let idElement = null;
    let fieldset = "div.form-fieldset.data-menu"
    $(fieldset).hide();
    $(fieldset).find('label.form-control-label').removeClass('required');
    let saveAndReturn = $('button.action-saveAndReturn').removeAttr('data-element');
    let saveAndAddAnother = $('button.action-saveAndAddAnother').removeAttr('data-element');
    let saveAndContinue = $('button.action-saveAndContinue').removeAttr('data-element');
    console.log(select);
    switch (select){
        case "Actuality":
        case "Article":
        case "Category":
        case "Product":
        case "OrderForm":
            $(fieldset+"."+select).show();
            $(fieldset+"."+select).find('label.form-control-label').addClass('required');
            idElement = select.toLowerCase();
            break;
        case "ProductList":
            $(fieldset+".Category").show();
            break;
        case "ArticleList":
        case "ActualityList":
            $(fieldset+".Tag").show();
            break;
    }
    if(idElement){
        saveAndReturn.attr('data-element',idElement);
        saveAndAddAnother.attr('data-element',idElement);
        saveAndContinue.attr('data-element',idElement);
    }
}


export default class extends Controller {
    connect() {
        let id = this.element.id;
        let menuType = $("#"+id);
        $(document).ready(function (){

            menuType.off('change');
            menuType.on('change',function(){
                changeVisible(id);
            });
            changeVisible(id);
        });
        $('button.action-saveAndReturn').on('click', this.save);
        $('button.action-saveAndAddAnother').on('click', this.save);
        $('button.action-saveAndContinue').on('click', this.save);
    }

    save(){
        let button = $(this);
        let id = button.attr('data-element');
        if(id){
            let $selectElement = $("#MenuItem_"+id+"-ts-control");
            let select = $selectElement.find("div.item").attr("data-value");
            if(select){
                return true;
            }else{
                $selectElement.parent().parent().parent().addClass('has-error');
            }
        }
        else{
            return true;
        }

        return false;
    }
}
